<template>
  <el-dialog
    :title="modalType === 'edit' ? '编辑账户' : '新增账户'"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="代理商：" prop="operationId">
        <el-select placeholder="请选择代理商" v-model="modalData.operationId" @change="changeAgent">
          <el-Option
            v-for="(item, index) in agentList.list"
            :key="index"
            :label="item.operationName"
            :value="item.id"
          ></el-Option>
        </el-select>
      </el-form-item>

      <el-form-item label="站点" prop="stationId">
        <el-select placeholder="请选择站点" v-model="modalData.stationId">
          <el-Option
            v-for="(item, index) in stationList.agentMap[this.modalData.operationId] || []"
            :key="index"
            :label="item.stationName"
            :value="item.id"
          ></el-Option>
        </el-select>
      </el-form-item>

      <el-form-item label="昵称：" prop="nickName">
        <el-input
          v-model="modalData.nickName"
          placeholder="请输入昵称"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="用户名：" prop="userName">
        <el-input
          v-model="modalData.userName"
          placeholder="请输入用户名"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="密码："
        prop="passwordHash"
        :rules="{
          required: this.modalType === 'add',
          message: '请输入密码',
          trigger: 'change',
        }"
      >
        <el-input
          type="password"
          v-model="modalData.passwordHash"
          placeholder="请输入密码"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="手机号码：" prop="phone">
        <el-input
          v-model="modalData.phone"
          placeholder="请输入手机号码"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="邮箱：" prop="email">
        <el-input
          v-model="modalData.email"
          placeholder="请输入邮箱"
          :maxLength="50"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import {validatorForm} from '@/common/util'

export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType", "agentList", "stationList"],
  data() {
    return {
      formName: "form",
      ruleValidate: {
        nickName: [
          {
            required: true,
            message: "请输入昵称不能为空",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "change",
          },
          {validator: validatorForm.phone}
        ],

        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "change",
          },{validator: validatorForm.email}
        ],
        userName: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "change",
          },
        ],
        operationId: [
          {
            required: true,
            message: "请选择代理商",
            trigger: "change",
          },
        ],
        stationId: [
          {
            required: true,
            message: "站点不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    changeSign(time) {
      if (!this.validatenull(time)) {
        this.defaultTime = time[1];
      }
    },

    changeAgent() {
      this.modalData.stationId = '';
    },

    handleChange(e) {},

    submit() {
      this.validateForm().then((res) => {
        let obj = this.deepClone(this.modalData);
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
